import React from "react"
import Layout from "../components/layout/Layout.js"
import { Section } from "../components/section/Section.js"
import { BlogRow } from "../components/row/Row.js"
import { TextMedium } from "../components/text/Text"
import { BlogTitle } from "../components/title/Title"
import Seo from '../components/seo'


function TrainingSubscribeSuccess () {
  return (
    <Layout>
      <Seo
        title="Thanks for Subscribing!"
        description="Thanks for subscribing to our newsletter! Please check your email address for a confirmation message."
      />
      <Section>
        <BlogRow>
          <BlogTitle isPrimary title="Thanks for subscribing to our training newsletter!"/>
          <TextMedium textMedium="Please check your email address for a confirmation message." />
          <TextMedium
            textMedium="We will send you updates when we add new live training sessions, webinars, online courses or 
            e-books to our library of resources." /> 
          <TextMedium textMedium="All our subscribers receive discount codes for these sessions, so keep 
          an eye on the e-mails." />
          <TextMedium textMedium="Thanks again and happy reading!" />
        </BlogRow>
      </Section>
    </Layout>
  )
}

export default TrainingSubscribeSuccess