import React from "react"
import { Column100, Column25 } from "../column/Column.js"
import { FooterContainer, FooterRow, FooterLink} from "./style.js"
import { SubHeading } from "../title/Title.js"
import { TextMedium, TextSmall } from "../text/Text"
import SocialProfiles from "../../blocks/SocialProfiles.js"
import { NavLogo, LogoFooter } from "../navigation/style.js"
import logo from "./../../static/schoolofcontent.png"

function Footer() {
  return (
    <FooterContainer>
      <FooterRow>
        <Column25>
          <NavLogo>
            <a href="/">
              <LogoFooter src={logo} />
            </a>
          </NavLogo>
          <TextMedium isPrimary textMedium="Level up your content strategy and marketing game through on-demand courses and get access to frameworks and toolkits used by top companies all over the world." />
          <SocialProfiles />
        </Column25>
        <Column25 isCentered>
          <SubHeading isPrimary subheading="Resources" />
          <FooterLink href="/content-strategy-training/" anchortext="Courses" />
          <FooterLink href="/content-strategy-templates/" anchortext="Toolkits" />
          <FooterLink href="/blog/" anchortext="Blog articles" />
          <FooterLink href="/case-studies/" anchortext="Case studies" />
          <FooterLink href="https://chat.openai.com/g/g-SdN6HfKei-content-strategist-gpt" anchortext="Content Strategist GPT" />    
        </Column25>
        <Column25 isCentered>
          <SubHeading isPrimary subheading="Services" />
          <FooterLink href="/content-strategy-consulting/" anchortext="Consulting" />
          <FooterLink href="/content-strategy/" anchortext="Content strategy" />
          <FooterLink href="/content-audit/" anchortext="Content audit" />
          <FooterLink href="/prices/" anchortext="Price list" />
        </Column25>
        <Column25 isCentered>
          <SubHeading isPrimary subheading="Contact" />
          <FooterLink href="/about/" anchortext="About us" />
          <FooterLink href="/contact/" anchortext="Get in touch" />    
          <FooterLink href="/privacy/" anchortext="Privacy" />
          <FooterLink href="/cookies/" anchortext="Cookies" />      
        </Column25>
      </FooterRow>
      <FooterRow>
        <Column100 isCentered>
          <TextSmall isPrimary textSmall="KVK: 78086337 | BTW-id: NL003284887B41" />
          <p className="disclaimer" style={{color: `#fff`}}>
            &copy; Copyright {new Date().getFullYear()} <b>School of Content</b>
            . Built with ♥ for front end &{" "}
            <a href="https://www.gatsbyjs.org/">Gatsby</a>. Graphics via{" "}
            <a href="https://undraw.co/">unDraw</a>
          </p>
        </Column100>
      </FooterRow>
    </FooterContainer>
  )
}

export default Footer
