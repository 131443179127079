import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({
  description,
  lang,
  meta,
  featuredImage,
  image: metaImage,
  title,
  pathname,
  noindex,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            keywords
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null
  const image =
    metaImage && metaImage.src
      ? `${site.siteMetadata.siteUrl}${metaImage.src}`
      : null
  const ogImage = `https://schoolofcontent.net${featuredImage}`

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={`%s | ${site.siteMetadata.title}`}
        link={
          canonical
            ? [
                {
                  rel: "canonical",
                  href: canonical,
                },
              ]
            : []
        }
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            name: "keywords",
            content: site.siteMetadata.keywords,
          },
        
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          { 
            property: `og:image`,
            content: ogImage
          },
          {
            property: "og:image:width",
            content: ogImage.width,
          },
          {
            property: "og:image:height",
            content: ogImage.height,
          },
          {
            property: `og:url`,
            content: canonical
          },
          { 
            property: `fb:app_id`,
            content: `1016402845453932`
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
          {
            name: `twitter:image`,
            content: ogImage
          },
          { 
            name: `twitter:creator`,
            content: `@Content_School`
          }
        ]
          .concat(
            metaImage
              ? [
                  { 
                    name: "image",
                    property: "og:image",
                    content: image,
                  },
                  {
                    property: "og:image:width",
                    content: metaImage.width,
                  },
                  {
                    property: "og:image:height",
                    content: metaImage.height,
                  },
                  {
                    name: "twitter:card",
                    content: "summary_large_image",
                  },
                ]
              : [
                  {
                    name: "twitter:card",
                    content: "summary",
                  },
                ]
          )
          .concat(meta)}
      />
    </>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  pathname: PropTypes.string,
}

export default Seo
