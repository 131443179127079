import React from "react"
import styled from "styled-components"
import { colors } from "../styles/colors"
import { sizes } from "../styles/sizes"
import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTwitter,
  faFacebook,
  faMedium,
  faLinkedinIn,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons"

library.add(faMedium, faLinkedinIn, faFacebook, faTwitter, faYoutube)

export const SocialList = styled.ul`
  list-style: none;
  margin: 0;
  padding: ${sizes.sizeM} 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;

  li {
    padding: ${sizes.sizeM} ${sizes.sizeXS};
  }

  a {
    color: ${colors.colorTextDark};
    text-decoration: none;

    &:visited {
      color: ${colors.colorTextDark};
    }

    &:hover {
      color: ${colors.colorContentDark};
    }
  }
`

function SocialProfiles() {
  return (
    <SocialList>

      <li>
        <a
          href="https://www.linkedin.com/company/school-of-content/"
          rel="noopener"
        >
          <FontAwesomeIcon icon={faLinkedinIn} size="lg" style={{color: "#ffffff"}}/>
        </a>
      </li>
      <li>
        <a href="https://www.facebook.com/SchoolOfContent" rel="noopener">
          <FontAwesomeIcon icon={faFacebook} size="lg" style={{color: "#ffffff"}}/>
        </a>
      </li>
      <li>
        <a href="https://www.youtube.com/@SchoolContentNet" rel="noopener"> 
          <FontAwesomeIcon icon={faYoutube} size='lg' style={{color: "#ffffff"}}/> 
        </a>
        </li>
    </SocialList>
  )
}

export default SocialProfiles
