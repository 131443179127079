import React from "react"
import Navigation from "../navigation/Navigation"
import { Main, StyledContentBody } from "./style.js"
import Footer from "../footer/Footer"

const Layout = props => {

  return (
    <>
      <Navigation />
      {/* <Navigation siteTitle={data.site.siteMetadata.title} /> */}
      <StyledContentBody>
        <Main lang="en-US">{props.children}</Main>
      </StyledContentBody>
      <Footer />
    </>
  )
}


export default Layout
