import styled from "styled-components"
import { colors } from "../../styles/colors"
import { sizes, device } from "../../styles/sizes"
import { fonts } from "../../styles/typography"
import { Row } from "../row/Row"
import { NewFooterLink } from "../link/Link"

export const FooterContainer = styled.footer`
  font-family: ${fonts.fontPrimary};
  margin: 0;
  padding: 50px 40px;
  background-color: ${colors.colorTextDark};
  color: ${colors.colorWhite} !important;
  text-rendering: optimizeLegibility;
  width: auto;

  @media ${device.tablet} {
    width: auto;
    padding: 50px 80px;
  }

  @media ${device.laptop} {
    width: auto;
    padding: 50px 80px;
  }

  @media ${device.desktop} {
    width: auto;
    padding: 50px 80px;
  }

  .disclaimer {
    font-size: ${sizes.sizeS};
    a {
      text-decoration: none;
      color: ${colors.colorWhite} !important;
    }
  }

  a {
    font-weight: 400 !important;
  }
`

export const FooterRow = styled(Row)`
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  font-size: ${sizes.sizeM};
  color: ${colors.colorWhite} !important;
`

export const FooterLink = styled(NewFooterLink)`
  color: ${colors.colorWhite} !important;
  text-align: center;

  a {
    color: ${colors.colorWhite} !important;
  }
  
  &:hover {
    color: ${colors.colorContentDark};
  }
`
