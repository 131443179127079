import React, { useState } from "react"
import {
  NavWrapper,
  NavList,
  NavItem,
  NavLink,
  NavMiddle,
  Logo,
  NavLogo,
  NavLinks,
  StyledBurger,
  SideMenu,
  BurgerLine 
} from "./style.js"
import { Contact } from "../button/Button.js"
import logo from "./../../static/schoolofcontent.png"


const Burger = () => {
  const [open, setOpen] = useState(false)

  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)}>
      <BurgerLine open={open}></BurgerLine>
      <BurgerLine open={open}></BurgerLine>
      <BurgerLine open={open}></BurgerLine>
      <SideMenu open={open}>
        <NavItem>
          <NavLink href="/content-strategy-training/">Courses</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/content-strategy-templates/">Toolkits</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/content-strategy-consulting/">Consulting</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/blog/">Blog</NavLink>
        </NavItem>
        <NavItem>
          <Contact buttonText="contact" buttonLink="/contact/" />
        </NavItem>
      </SideMenu>
    </StyledBurger> 
  )
}

const Navigation = () => {
  const [open, setOpen] = useState(false)

  return (
    <NavWrapper>
      <NavList>
        <NavLogo>
          <a href="/">
            <Logo src={logo} />
          </a>
        </NavLogo>
        <Burger open={open} onClick={() => setOpen(!open)} />
        <NavLinks open={open}>
          <NavMiddle>
            <NavItem>
              <NavLink href="/content-strategy-training/">Courses</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/content-strategy-templates/">Toolkits</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/content-strategy-consulting/">Consulting</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/blog/">Blog</NavLink>
            </NavItem>
          </NavMiddle>
          <NavItem>
            <Contact buttonText="Contact" buttonLink="/contact/" />
          </NavItem>
        </NavLinks>
      </NavList>
    </NavWrapper>
  )
}

export default Navigation
