import styled from "styled-components"
import { colors } from "../../styles/colors.js"
import { sizes, device } from "../../styles/sizes.js"
import { fonts } from "../../styles/typography.js"
import { Link } from "gatsby"

export const NavWrapper = styled.nav`
  max-width: 100%;
  margin: 0 auto;
  padding: 0 80px;
  box-sizing: border-box;
  font-family: ${fonts.fontPrimary};
  text-rendering: optimizeLegibility;
`

export const NavList = styled.ul`
  width: auto;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;

  @media ${device.tablet} {
    list-style-type: none;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }

  @media ${device.laptop} {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
  }

  @media ${device.desktop} {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
  }
`

export const NavLinks = styled.div`
  justify-content: center;
  align-items: center;
  align-content: center;

  @media ${device.mobileXS} {
    display: ${props => (props.open ? "flex" : "none")};
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
    margin: auto;
    z-index: 1;
  }

  @media ${device.tablet} {
    display: ${props => (props.open ? "flex" : "none")};
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
    margin: auto;
  }

  @media ${device.laptop} {
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    width: 100%;
  }

  @media ${device.desktop} {
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    width: 100%;
  }
`

export const NavMiddle = styled.div`
  justify-content: center;
  align-items: center;
  align-content: center;

  @media ${device.mobileXS} {
    display: ${props => (props.open ? "flex" : "none")};
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
    margin: auto;
    z-index: 1;
  }

  @media ${device.tablet} {
    display: ${props => (props.open ? "flex" : "none")};
    flex-flow: column wrap;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    width: 80%;
    margin: auto;
  }

  @media ${device.laptop} {
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    width: 80%;
  }

  @media ${device.desktop} {
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    width: 80%;
  }
`


export const NavItem = styled.li`
  text-decoration: none;
  margin: 0 ${sizes.sizeS};
  line-height: 1.4;
  padding: 4px 0;
  font-size: ${sizes.sizeM};
  font-weight: 600;
  position: relative;

  @media ${device.mobileS} {
    &:last-of-type {
      margin-top: ${sizes.sizeL};
    }
  }

  @media ${device.laptop} {
    &:first-of-type {
      margin-left: ${sizes.sizeXXL};
      display: inline-block;
    }

    &:last-of-type {
      margin-right: ${sizes.sizeXXL};
      margin-top: 0;
    }
  }
`

export const NavLogo = styled.div`
  display: inline-block;
  margin: 0;
  margin-left: -20px !important;
  line-height: 1.4;
  padding: 4px 0;

  @media ${device.laptop} {
    margin: 0 ${sizes.sizeM} 0 0;
  }

  @media ${device.desktop} {
    margin: 0 ${sizes.sizeM} 0 0;
  }
`

export const NavLink = styled(Link)`
  text-decoration: none;
  color: ${colors.colorTextDark};

  &:hover {
    color: ${colors.colorContentDark};
  }
`

export const Logo = styled.img`
  object-fit: cover;
  width: 120px;
  height: 64px;
`

export const LogoFooter = styled.img`
  object-fit: cover;
  width: 86px;
  height: 48px;
`

export const StyledBurger = styled.div`
  position: fixed;
  right: 4vw;
  top: 1.5rem;
  margin: 0 4vw;
  
  height: 3rem;

  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  
  margin-bottom: ${props => (props.open ? `4vw` : `0`)};

  @media ${device.laptop} {
    display: none;
  }

  @media ${device.desktop} {
    display: none;
  }
`

export const SideMenu = styled.div`
  position: fixed;
  top: 6rem;
  right: 0rem;
  left: 0rem;
  padding: ${sizes.sizeL};
  width: auto;
  z-index: 1;

  background: ${props =>
    props.open ? `${colors.colorContentLightest}` : `${colors.white}`};
  border-radius: 2rem;
  margin-bottom: ${props => (props.open ? `${sizes.sizeM}` : `0`)};

  justify-content: center;
  align-items: center;
  align-content: center;

  display: ${props => (props.open ? "flex" : "none")};
  flex-flow: column wrap;

  @media ${device.tablet} {
    display: ${props => (props.open ? "flex" : "none")};
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  
  @media ${device.laptop} {
    display: none;
  }

  @media ${device.desktop} {
    display: none;
  }
`


export const BurgerLine = styled.div`
    background-color: ${colors.colorTextDark};
    height: 0.3rem;
    width: 3rem;
    align-self: center;
    transition: all linear;
    
    &:first-child {
      transform: ${props => props.open ? "rotate(45deg) translateX(8px)" : "rotate(0deg)"};
      transform-origin: top left;
    }
    
    &:nth-child(2) {
      opacity: ${props => (props.open ? "0" : "1")};
    }

    &:nth-child(3){
      transform: ${props => props.open ? "rotate(-45deg) translateX(6px)" : "rotate(0deg)"};
      transform-origin: bottom left;
    }
`