import styled from "styled-components"
import { fonts } from "../../styles/typography.js"

export const StyledContentBody = styled.section`
  max-width: 100%;
  margin: 0 auto;
  font-family: ${fonts.fontPrimary};
  box-sizing: border-box;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
`

export const Main = styled.div`
  font-family: ${fonts.fontPrimary};
  margin: 0 auto;
  text-rendering: optimizeLegibility;
`
